<template>
  <div>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{name}}
          </v-list-item-title>
          
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item-group
          v-model="selected_item"
          active-class="grey lighten-4"
          color="grey darken-4"

        >
        
           <v-list-item
           
           v-for="(item,i) in items"
           :key="i"
           :disabled="item.disabled"

           link

           @click="drawer_action(item.route,i)"
           >
           <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }} </v-list-item-title>
          </v-list-item-content>
            
        </v-list-item>

        </v-list-item-group>

      </v-list>
    </div>
</template>

<script>
  // import { mapState } from 'vuex'
  export default {
    name: 'SideMenu',

    data: () => ({
      selected_item:2,
      items: [
      { title: 'Dashboard', 
      route:'/dashboard',
      icon: 'mdi-view-dashboard',
      disabled:false },

      { title: 'Search',
      route:'/search', 
      icon: 'mdi-text-search', 
      disabled:false},

      { title: 'Business Changes',
      route:'/business-change', 
      icon: 'mdi-poll' , 
      disabled:false},

      { title: 'Activities Trend',
      route:'/activity-trend', 
      icon: 'mdi-trending-up' , 
      disabled:false},

      { title: 'Activities Stat',
      route:'/activities-stat', 
      icon: 'mdi-briefcase-plus-outline' , 
      disabled:false},

       { title: 'Activities Rate',
      route:'/activity-rate', 
      icon: 'mdi-chart-timeline-variant-shimmer' , 
      disabled:false},

      // { title: 'Activity Change Address',
      // route:'/activity-change-address', 
      // icon: 'mdi-map-marker-path' , 
      // disabled:false},

      

      //  { title: 'Deleted Activities Trend',
      // route:'/deleted-activity-trend', 
      // icon: 'mdi-trending-down' , 
      // disabled:false},


      // { title: 'Deleted Activities Stat',
      // route:'/deleted-activities-stat', 
      // icon: 'mdi-briefcase-remove-outline' , 
      // disabled:false},

     
      // { title: 'Deleted Activities Rate',
      // route:'/deleted-activity-rate', 
      // icon: 'mdi-chart-timeline-variant' , 
      // disabled:false},
      

     
      
      // { title: 'Acitvity Capital',
      // route:'/activity-capital', 
      // icon: 'mdi-currency-usd' , 
      // disabled:true},

      { title: 'Acitvity Map',
      route:'/activity-map', 
      icon: 'mdi-map-marker' , 
      disabled:false},
      
      // { title: 'Area Activity',
      // route:'/area-activity', 
      // icon: 'mdi-file-table-box-multiple-outline' , 
      // disabled:false},

       { title: 'Block Summary',
      route:'/block-summary', 
      icon: 'mdi-table-multiple' , 
      disabled:false},

       { title: 'Area Block Details',
      route:'/block-details', 
      icon: 'mdi-file-table-box-multiple-outline' , 
      disabled:false},

      { title: 'Opportunities',
      route:'/opportunities', 
      icon: 'mdi-office-building-marker-outline' , 
      disabled:false},

      { title: 'Unfortunate Attempt',
      route:'/unfortunate-attempt', 
      icon: 'mdi-alert-box-outline' , 
      disabled:false},

      // { title: 'Exports',
      // route:'/exports', 
      // icon: 'mdi-database-export-outline' , 
      // disabled:true},
      { title: 'Market',
      route:'/market', 
      icon: 'mdi-filter-variant', 
      disabled:false },

      { title: 'Subscription',
      route:'/subscription', 
      icon: 'mdi-credit-card-settings-outline' , 
      disabled:true},

      { title: 'Invite',
      route:'/invitation', 
      icon: 'mdi-message-arrow-right-outline' , 
      disabled:false},

      { title: 'Profile',
      route:'/profile', 
      icon: 'mdi-card-account-details-outline' , 
      disabled:false},

      

      { title: 'Logout',
      route:'/logout', 
      icon: 'mdi-exit-to-app', 
      disabled:false},
      ],
  }),
    methods: {
    drawer_action: function(r,i) {
      this.selected_item=i;
      console.log("select item:",this.selected_item);
      console.log("Drawer item clicked",r);
      console.log("Path is: ",this.$route.path);
      if (r=="/logout"){
        this.$store.commit("logout");
        this.$store.commit("clear_market_list");
        this.$router.push({ path: '/login' })
      }else{
        if (this.$route.path != r){
          this.$router.push({ path: r })
        }
      }
    },
  },

    computed: {
      name(){
        return this.$store.state.user.name;
    },
  },

  created:function(){
    // set menu item
    let path=this.$route.path;

    //spcial case since it is not item in the drawer
    if (path =="/opportunities-map"){
      path="/opportunities"
    }
    for (let i=0;i<this.items.length;i++){
      if (this.items[i].route==path){
        this.selected_item=i;
      }
    }
  }
      // subscription_end_date(){
      //   const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      //   const a = new Date();
      //   const b = new Date(this.$store.state.user.subscription_end_date);
      //   // const b = new Date("2021-01-01");
      //   // console.log("End date: ",this.$store.state.user.subscription_end_date);
        
      //   const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      //   const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
      //   let days=Math.floor((utc2 - utc1) / _MS_PER_DAY)
      //   if (days <=0){
      //     days=0;
      //   }
      //   return days+" days";
      // }

}
</script>
