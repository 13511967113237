<template>
  <v-app id="inspire">
    <div id="side-menu" v-if="$route.meta.show_side_menu==true">
      
      
        <v-navigation-drawer
        v-model="drawer"
        app
        

        
        class="grey lighten-5"
        >
        <side-menu />
        <template v-slot:append>
        
        <p class="ma-2 caption font-weight-normal">  
            V: {{version}}
        </p>
      </template>

        </v-navigation-drawer>
      

      <v-app-bar app flat >
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>{{currentRouteName}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <router-link class="view_opp_btn" to="/features">
          <v-btn
          text
           >
          <v-icon>mdi-information-outline</v-icon>
          </v-btn>
        </router-link>
  

      </v-app-bar>
    </div>
   
    <v-main class="main">
      <keep-alive 
        :exclude="no_cache"
        max="5">
        <router-view :key="$route.fullPath"></router-view>
      </keep-alive>
    </v-main>
  </v-app>
</template>

<script>
import SideMenu from './components/SideMenu';

export default {
  name: 'App',
  components: {
    SideMenu
  },


  data: () => ({
    drawer: null,
    version:process.env.VUE_APP_VERSION,
    login_session:"",
    no_cache:["BranchDetails","OpporutinityDetails","Market","Dashboard"]
  }),
  computed: {
    currentRouteName() {
        return this.$route.name;
    },
  },

};
</script>
<style scoped>
  .main{
    background-color: #ecf0f1;
  }
  .view_opp_btn{
  text-decoration: none;
}
</style>