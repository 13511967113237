const state ={
	market_list:[]
}
const mutations ={
	add_market(state,data){
		let market={
			"name":data.name,
			"area_object_id":data.area_object_id,
			"activity_object_id":data.activity_object_id,
			"object_id":data.object_id,
			"created_at":data.created_at,
			"default":false,
		}
		state.market_list.push(market)

	},
	clear_market_list(state){
		state.market_list=[]
	},
	set_default_market(state,object_id){
		for(let i=0;i<state.market_list.length;i++){
			if(state.market_list[i].object_id == object_id){
				state.market_list[i].default=true;
			}else{
				state.market_list[i].default=false;
			}
		}
	},
	delete_market(state,object_id){
		state.market_list = state.market_list.filter(function(item) {
			return item.object_id !== object_id
		});
	},
	set_market(state,market_list){
		for (let i =0;i<market_list.length;i++){
			if (i==0){
				market_list[i].default=true;
			}else{
				market_list[i].default=false;
			}
			state.market_list.push(market_list[i])
		}
		
	},
}


export default {
	state,
	mutations
}