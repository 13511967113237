import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'
import store from '../store' // your vuex store 

Vue.use(VueRouter)

const DEFAULT_TITLE = 'Next Level';

const ifNotAuthenticated = (to, from, next) => {
  // console.log("Check if user with no session key");
  // console.log("route session check[NOTAUTH]",store.getters.session_key)
  if (store.getters.session_key ==null) {
    next()
    return
  }
  next('/dashboard')
}

const ifAuthenticated = (to, from, next) => {
  console.log("Check if user with session key");
  // console.log("route session check[AUTH]",store.getters.session_key)
  if (store.getters.session_key !=null) {
    next()
    return
  }
  next('/login')
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      show_side_menu:false,
      title: 'Next Level Bahrain',
    }
  },

  {
    path: '/too-many-requests',
    name: 'TooManyRequests',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/TooManyRequests.vue'),
    meta: {
      show_side_menu:false,
      title: 'Too Many Request',
    }
  },
  {
    path: '/add-market',
    name: 'AddMarket',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/AddMarket.vue'),
    meta: {
      show_side_menu:false,
      title: 'Add Market',
    }
  },
  {
    path: '/features',
    name: 'Features',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Features.vue'),
    meta: {
      show_side_menu:true,
      title: 'Features',
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: {
      show_side_menu:true,
      title: 'Dashbaord',
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Search.vue'),
    meta: {
      show_side_menu:true,
      title: 'Search',
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/business-change',
    name: 'Business Change',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/BusinessChange.vue'),
    meta: {
      show_side_menu:true,
      title: 'Business Change',
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/added-activities-stat',
    name: 'Added Activities Stat',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/AddedActivitiesStat.vue'),
    meta: {
      show_side_menu:true,
      title: 'Added Activities Stat',
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/activities-stat',
    name: 'Activities Stat',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/ActivitiesStat.vue'),
    meta: {
      show_side_menu:true,
      title: 'Activities Stat',
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/deleted-activities-stat',
    name: 'Deleted Activities Stat',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/DeletedActivitiesStat.vue'),
    meta: {
      show_side_menu:true,
      title: 'Deleted Activities Stat',
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/branch/:objectId',
    name: 'Branch Details',
    component: () => import(/* webpackChunkName: "Branch Details" */ '../views/BranchDetails.vue'),
    meta: {
      show_side_menu:true,
      title: 'Branch Details',

    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/activity-rate',
    name: 'Activity Rate',
    component: () => import(/* webpackChunkName: "opportunities" */ '../views/ActivityRate.vue'),
    meta: {
      show_side_menu:true,
      title: 'Activity Rate',
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/activity-trend',
    name: 'Activity Trend',
    component: () => import(/* webpackChunkName: "opportunities" */ '../views/ActivitiesTrend.vue'),
    meta: {
      show_side_menu:true,
      title: 'Activity Trend',
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/added-activity-trend',
    name: 'Added Activity Trend',
    component: () => import(/* webpackChunkName: "opportunities" */ '../views/AddedActivitiesTrend.vue'),
    meta: {
      show_side_menu:true,
      title: 'Added Activity Trend',
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/deleted-activity-trend',
    name: 'Deleted Activity Trend',
    component: () => import(/* webpackChunkName: "opportunities" */ '../views/DeletedActivitiesTrend.vue'),
    meta: {
      show_side_menu:true,
      title: 'Deleted Activity Trend',
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/deleted-activity-rate',
    name: 'Deleted Activity Rate',
    component: () => import(/* webpackChunkName: "opportunities" */ '../views/DeletedActivityRate.vue'),
    meta: {
      show_side_menu:true,
      title: 'Deleted Activity Rate',
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/area-activity',
    name: 'Area Activity',
    component: () => import(/* webpackChunkName: "opportunities" */ '../views/AreaActivity.vue'),
    meta: {
      show_side_menu:true,
      title: 'Area Activity',
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/block-summary',
    name: 'Block Summary',
    component: () => import(/* webpackChunkName: "opportunities" */ '../views/BlockSummary.vue'),
    meta: {
      show_side_menu:true,
      title: 'Block Summary',
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/block-details',
    name: 'Block Details',
    component: () => import(/* webpackChunkName: "opportunities" */ '../views/BlockDetails.vue'),
    meta: {
      show_side_menu:true,
      title: 'Block Details',
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/opportunities',
    name: 'Opportunities',
    component: () => import(/* webpackChunkName: "opportunities" */ '../views/opportunity/Opportunities.vue'),
    meta: {
      show_side_menu:true,
      title: 'Opportunities',
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/opportunities-map',
    name: 'Opportunities Map',
    component: () => import(/* webpackChunkName: "opportunities" */ '../views/opportunity/OpportunitiesMap.vue'),
    meta: {
      show_side_menu:true,
      title: 'Opportunities Map',
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/unfortunate-attempt',
    name: 'Unfortunate Attempt',
    component: () => import(/* webpackChunkName: "opportunities" */ '../views/opportunity/UnfortunateAttempt.vue'),
    meta: {
      show_side_menu:true,
      title: 'Unfortunate Attempt',
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/opportunity-details/:objectId',
    name: 'Opportunity Details',
    component: () => import(/* webpackChunkName: "opportunity-details" */ '../views/opportunity/OpportunityDetails.vue'),
    meta: {
      show_side_menu:true,
      title: 'Opportunity Details',
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/activity-map',
    name: 'Activity Map',
    component: () => import(/* webpackChunkName: "activity-map" */ '../views/ActivityMap.vue'),
    meta: {
      show_side_menu:true,
      title: 'Activity Map',
    },
    beforeEnter: ifAuthenticated,
  },
   {
    path: '/activity-change-address',
    name: 'Activity Change Address',
    component: () => import(/* webpackChunkName: "activity-map" */ '../views/ActivityChangeAddress.vue'),
    meta: {
      show_side_menu:true,
      title: 'Activity Change Address',
    },
    beforeEnter: ifAuthenticated,
  },
  // {
  //   path: '/subscription',
  //   name: 'Subscription',
  //   component: () => import(/* webpackChunkName: "opportunity-details" */ '../views/Subscription.vue'),
  //   meta: {
  //     show_side_menu:true,
  //     title: 'Subscription',
  //   },
  //   beforeEnter: ifAuthenticated,
  // },
  {
    path: '/market',
    name: 'Market',
    component: () => import(/* webpackChunkName: "opportunity-details" */ '../views/Market.vue'),
    meta: {
      show_side_menu:true,
      title: 'Market',
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/user/Login.vue'),
    meta: {
      show_side_menu:false,
      title: 'Login',
    },
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/user/Signup.vue'),
    meta: {
      show_side_menu:false,
      title: 'Signup',
    },
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/invite/:code',
    name: 'Invite',
    component: () => import(/* webpackChunkName: "signup" */ '../views/user/SignupInvite.vue'),
    meta: {
      show_side_menu:false,
      title: 'Invitaion',
    },
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: () => import(/* webpackChunkName: "signup" */ '../views/user/ResetPassword.vue'),
    meta: {
      show_side_menu:false,
      title: 'Reset Password',
    },
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "signup" */ '../views/user/Profile.vue'),
    meta: {
      show_side_menu:true,
      title: 'Profile',
    },
    beforeEnter: ifAuthenticated,
  },
   {
    path: '/invitation',
    name: 'Invitation',
    component: () => import(/* webpackChunkName: "signup" */ '../views/user/Invitation.vue'),
    meta: {
      show_side_menu:true,
      title: 'Invitation',
    },
    beforeEnter: ifAuthenticated,
  },
  {
     path: '*',
     name:'NotFound',
     component: () => import(/* webpackChunkName: "404" */ '../views/NotFound.vue'),
     meta: {
      show_side_menu:false,
      title:'NotFound',
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});



export default router
