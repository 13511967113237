import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import user from './modules/user';
import market from './modules/market';
import top_help from './modules/top_page_info';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    market,
    top_help,
  },
  getters: {
    name: state => state.user.name,
    phone: state => state.user.phone,
    email: state => state.user.email,
    subscription_active: state => state.user.subscription_active,
    subscription_start_date: state => state.user.subscription_start_date,
    subscription_end_date: state => state.user.subscription_end_date,
    subscription_package: state => state.user.subscription_package,
    session_key: state => state.user.session_key,
    market_list: state=> state.market.market_list,
    top_help: state=> state.top_help,
    
  },
  plugins:[createPersistedState()]
})
