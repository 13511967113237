const state ={
	dashboard:true,
	opportunity_list:true,
	unfortunate_attempt:true,
}

const mutations ={
	reset_help(state){
		state.dashboard=true;
		state.opportunity_list=true;
		state.unfortunate_attempt=true;
	},
	hide_all_help(state){
		state.dashboard=false;
		state.opportunity_list=false;
		state.unfortunate_attempt=false;
	},
	hide_help(state,prop){
		console.log("hide ", prop);
		state[prop]=false;
	},
}


export default {
	state,
	mutations
}