const state ={
	session_key:null,
	name:null,
	phone:null,
	email:null,
	subscription_active:null,
	subscription_start_date:null,
	subscription_end_date:null,
	subscription_package:null,
}

const mutations ={
	logout(state){
		state.session_key=null;
		state.name=null;
		state.phone=null;
		state.email=null;
		state.status=null;
		state.subscription_active=null;
		state.subscription_start_date=null;
		state.subscription_end_date=null;
		state.subscription_package=null;
	},
	login(state,data){
		state.session_key=data.session_key;
		state.name=data.name;
		state.phone=data.phone;
		state.email=data.email;
		state.status=data.status;

		state.subscription_active=data.subscription_active;
		state.subscription_start_date=data.subscription_start_date;
		state.subscription_end_date=data.subscription_end_date;
		state.subscription_package=data.subscription_package;
	}
}


export default {
	state,
	mutations
}