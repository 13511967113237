<template>
	<section
		class="overflow-hidden main_section"

	>
		<v-row no-gutters>
			<v-col
				class="hidden-sm-and-down"
				md="6"
			>
				<v-img
					:src="require('@/assets/main.jpeg')"
					height="100vh"
				/>
			</v-col>

			<v-col
				
				cols="12"
				md="6"
			>

				<v-row
					align="center"
					justify="center"
				>
					<v-col
						cols="10"
						md="10"
					>
						<div class="half_page">
							

							<!-- <h1 class="text-h4">Next Level Bahrain</h1> -->
							<h2 class="mt-4 text-h4 font-weight-light">Interested in Bahrain market data?</h2>
							<p class=" mt-2 body-1 font-weight-light">
								Make better business decisions based on analyzed Bahrain market data and discover more opportunities to scale up your business.
							</p>
							<h2 class="text-h5 font-weight-light">Features</h2>
							<div class="mt-4">
								<v-row>
									<v-col md="6" cols="12"  v-for="feature in feature_list" :key="feature.title">
										<v-card
											outlined
											>
												<v-card-title>
													<v-icon  left > {{feature.icon}} </v-icon><span class="subtitle-1 font-weight-light">{{feature.title}}</span>
												</v-card-title>
												<v-card-text class="min_hight body-2 font-weight-light">{{feature.description}}</v-card-text>
											</v-card>
									</v-col>
								</v-row>

							</div>
							<div class="my-4">
								<div v-if="is_login">
									<v-btn class="mr-4 " outlined text href="/dashboard" >
										Dashboard
									</v-btn>	
								</div>
								<div v-else>
									<v-btn class="mr-4 " outlined text href="/login" >
										Login
									</v-btn>
									<v-btn  outlined text disabled>
										Request Access
									</v-btn>
								</div>
							</div>
						</div>
					</v-col>
				</v-row>

			</v-col>
		</v-row>
	</section>
</template>

<script>
// @ is an alias to /src


export default {
	name: 'Home',
	data: () => ({
		is_login:false,
		feature_list:[
		
			{
				"icon":"mdi-text-search",
				"title":"Search",
				"description":"Search for branches by name,business activity and status",
			},
			{
				"icon":"mdi-poll",
				"title":"Business changes",
				"description":"Get stat,trend and history of added and deleted business activity",
			},
	

			{
				"icon":"mdi-map-marker",
				"title":"Activity Map",
				"description":"Get location of business activity in the map",
			},

			{
				"icon":"mdi-office-building-marker-outline",
				"title":"New Opportunity",
				"description":"Discover location with existing customer base.",
			},

			]
	}),
	created:function(){
		if (this.$store.getters.session_key==null){
			this.is_login=false;
		}else{
			this.is_login=true;
		}
	},
}
</script>
<style scoped>
.main_section {
/*	background-color: #ffffff;*/
}
.half_page{
	margin-top: 10%;
}
</style>
